import { useState } from "react";
import Footer from "../Footer/Footer";
import axios from 'axios';

const Home = () => {
    const [hash, setHash] = useState("");
    const inputHandler = (e) => {
        const hashStatus = document.getElementById("hashStatus");
        hashStatus.innerHTML = "";
        hashStatus.className = "col-12 my-3 text-center";

        setHash(e.target.value);
    }
    const fetchData = async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}`, {plainText: hash});
        return response.data;
      } catch (error) {
        return error;
      } 
    };
    const onSubmit = (e) => {
        e.preventDefault();
        const hashStatus = document.getElementById("hashStatus");
        const hashInput = document.getElementById("hash");
        if (hash === "") {
            hashStatus.innerHTML = "Please enter a string to hash";
            hashStatus.className = "col-12 my-3 text-center text-danger";
            setHash("");
            hashInput.focus();
            return false;
        }
        fetchData().then((data) => {
            if (data.error) {
                hashStatus.innerHTML = data.message;
                hashStatus.className = "col-12 my-3 text-center text-danger";
                setHash("");
                hashInput.focus();
                return false;
            }
         hashStatus.innerHTML = "Hashed";
        hashStatus.className = "col-12 my-3 text-center text-success";
        hashInput.value = data;
        }).catch((error) => {
            hashStatus.innerHTML = "Error";
            hashStatus.className = "col-12 my-3 text-center text-danger";
        });
    
    }
    return (
        <div className="container">
            <div className="row justify-content-md-center">
                <h1 className="col text-center">function md5()</h1>
            </div>
            <div className="row justify-content-md-center">
                <h2 className="col text-center ">Online generator <a href="https://tools.ietf.org/html/rfc1321" title="RFC 1321 - The MD5 Message-Digest Algorithm" target="_blank" rel="noreferrer">md5 hash</a> of a string</h2>
            </div>
            <div className="form-horizontal">
                    <div className="form-group mb-2 row my-3">
                        <div className="col input-group input-group-sm pl-4 pr-5 inputMd5">
                            md5 (<input id="hashInput" type="text" value={hash} onChange={(e) => inputHandler(e)} className="form-control mx-1" placeholder="place here your string to hash" />)
                        </div>
                    </div>
                    <div className="col input-group-sm text-center">
                        <button type="submit" onClick={onSubmit} className="btn btn-info">Hash here</button>
                    </div>

                        <p id="hashStatus" className="col-12 my-3 text-center" />

                    <div className="col-12 input-group input-group-sm text-center my-3">
                        <input type="text" nazev="hash" id="hash" value="" className="form-control mx-1 border-0 px-0 text-center" readOnly placeholder="--" />
                    </div>

                </div>
             <div className="row mt-3"></div>
                <amp-ad width="100vw" height="320"
                    type="adsense"
                    data-ad-client="ca-pub-7492776353891642"
                    data-ad-slot="8935191590"
                    data-auto-format="rspv"
                    data-full-width="">
                    <div overflow=""></div>
                </amp-ad>
                <div className="row mt-3"></div>
                <hr className="mt-2" />
                <article className="indexArticle">
                    <h2>More about MD5</h2>
                    <p>The goal of MD5 development was to create a function/algorithm that quickly and without much computational power creates a unique digest for each unique string (message). From this digest, it must not be possible (without using brute force or rainbow tables) to reconstruct the original message backwards. The MD5 hash is 128 bits long and is represented by 32 characters.</p>
                    <p>However, already a year after its publication, it became clear that this function does not work properly and a situation can occur where the function produces the same digest for two different strings. This flaw is fundamental and makes the MD5 function completely unusable for cryptography, as it leads to a number of security holes. The use of the MD5 function has been strongly discouraged for many years.</p>
                    <p>Nevertheless, the MD5 function continues to be widely used to authenticate strings (most commonly probably passwords) or to verify data integrity in simple and non-critical applications where a brute force attack is not expected. To make it more difficult to attempt to decrypt digests created with MD5, a so-called salt, which is an arbitrary secret key known only to the author of the application, is added to the beginning or end of the text to be encrypted.</p>
                    <p>Recommendation: if you are creating your own application and need to create a digest of some text, consider using other encryption functions that have not been found to have any security flaws.</p>
                    <p>More info about MD5 (Message-Digest algorithm 5) on <a href="https://en.wikipedia.org/wiki/MD5" target="_blank" title="(Message-Digest algorithm 5) on Wikipedia" rel="noreferrer"><i className="fa fa-external-link-alt" aria-hidden="true"></i> Wikipedia.org</a></p>
                </article>
        <Footer />
        </div>
    )
    }

export default Home;