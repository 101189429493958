import Footer from "../Footer/Footer";

const Python = () => {
    return (
        <div>
         <main className="container">
                   <div className="row justify-content-md-center">
                    <h1 className="col text-center"><a href="/"><i className="fa fa-home pr-4"></i>function md5()</a></h1>
                </div>
            <div className="row justify-content-md-center py-4" />
            <h2 className="col text-center ">Using MD5 function in Python</h2>
         <article className="row">

    <div className="col-12">Python includes the <code>hashlib</code> encryption library, which can also be used for MD5. You can use digest() or hexdigest() methods.</div>

    
    
    <div className="col-12 mt-4">
        
        <h3>Basic example with using hexdigest() method:</h3>
        <p>Returns the encoded data in hexadecimal format.</p>
        <pre><code className="language-python hljs"><span className="hljs-keyword">import</span> hashlib
str2hash = <span className="hljs-string">"Hello world!"</span>
md5hash = hashlib.md5(str2hash.encode(<span className="hljs-string">'utf-8'</span>)).hexdigest()
<span className="hljs-built_in">print</span> (md5hash)</code></pre></div>


    <div className="col-12"><h4>The above example will output:</h4>
        <pre><code className="language-plaintext hljs">86fb269d190d2c85f6e0468ceca42a20</code></pre>
    </div>
       
    <div className="col-12 mt-4">
        <h3>Example #2 with with using digest() method:</h3>
        <p>Returns the encoded data in byte format</p>
        <pre><code className="language-python hljs"><span className="hljs-keyword">import</span> hashlib
str2hash = <span className="hljs-string">'Hello world!'</span>
<span className="hljs-built_in">print</span>(hashlib.md5(str2hash.encode(<span className="hljs-string">'utf-8'</span>)).digest())</code></pre></div>
    
    <div className="col-12"><h4>Example #2 will output:</h4>
        <pre><code className="language-plaintext hljs">b'\x86\xfb&amp;\x9d\x19\r,\x85\xf6\xe0F\x8c\xec\xa4* '</code></pre>
    </div>
    
    <div className="col-12 mt-4">
        <h3>Example #3 with salt before string to be encrypted:</h3>
        <p>In some cases, it is worth using a salted MD5 hash for added security. This means that you add the string "salt" before or after the string to be encrypted.</p>
        <pre><code className="language-python hljs"><span className="hljs-keyword">import</span> hashlib
salt = <span className="hljs-string">'i#8^*uu'</span> <span className="hljs-comment"># Your secret key</span>
str2hash = <span className="hljs-string">'Hello world!'</span>
<span className="hljs-built_in">print</span>(hashlib.md5(salt.encode(<span className="hljs-string">'utf-8'</span>) + str2hash.encode(<span className="hljs-string">'utf-8'</span>)).hexdigest())</code></pre></div>
    
    <div className="col-12"><h4>Example #3 will output:</h4>
        <pre><code className="language-plaintext hljs">5e5eb686808a7c39710dccf1ce03a4e6</code></pre>
    </div>
    
    
    <div className="col-12 externalSources">
        <h3>External resource about hashlib library:</h3>
        <a href="https://docs.python.org/3/library/hashlib.html" target="_blank"><i className="fas fa-external-link-alt" aria-hidden="true"></i> Python.org</a>
        
    </div>
    
</article>
         </main>
        <Footer />
        </div>
    );
    }

export default Python;