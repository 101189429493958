import Footer from "../Footer/Footer";

const MariaDB = () => {
    return (
        <div>
         <main className="container">
                   <div className="row justify-content-md-center">
                    <h1 className="col text-center"><a href="/"><i className="fa fa-home pr-4"></i>function md5()</a></h1>
                </div>
            <div className="row justify-content-md-center py-4" />
            <h2 className="col text-center ">Using MD5 function in MariaDB</h2>
         <article className="row">
    
    <div className="col-12">
        <p>Using the MD5 algorithm in MariaDB is very easy because it has a native MD5() function.</p>
        <p>The return value is a 32-hex digit string, and as of MariaDB 5.5, is a nonbinary string in
        the connection character set and collation, determined by the values of the <code>character_set_connection</code> and <code>collation_connection</code> system variables.
        Before 5.5, the return value was a binary string.</p>
        <p><code>NULL</code> is returned if the argument was <code>NULL</code>.</p>
    </div>
    <div className="col-12 mt-4"><h3>Syntax:</h3>
        <pre><code className="language-sql hljs">MD5(string)</code></pre>
    </div>
    
    <div className="col-12">
        
        <h3>Basic example:</h3>
        <pre><code className="language-sql hljs"><span className="hljs-keyword">SELECT</span> MD5(<span className="hljs-string">'Hello world!'</span>);</code></pre></div>


    <div className="col-12"><h4>The above example will output:</h4>
        <pre><code className="language-plaintext hljs">86fb269d190d2c85f6e0468ceca42a20</code></pre>
    </div>
 
    <div className="col-12 mt-4">
        <h3>Example #2 with salt before string to be encrypted:</h3>
        <p>In some cases, it is worth using a salted MD5 hash for added security. This means that you add the "salt string" before or after the string to be encrypted.</p>
        <pre>            <code className="language-sql hljs"><span className="hljs-keyword">SELECT</span> MD5(CONCAT(<span className="hljs-string">'yourSalt'</span>, <span className="hljs-string">'Hello world!'</span>));</code>
        </pre></div>
    
    <div className="col-12"><h4>Example #2 will output:</h4>
        <pre><code className="language-plaintext hljs">a17602be9d6201a338243d8d7693f5bf</code></pre>
    </div>
    
    <div className="col-12">
        <p><i>Note: Be aware that using encryption functions in a database usually means that the original, unencrypted text will be stored in the database log, which in some cases can be a potential security risk.</i></p>
    </div>
    <div className="col-12 externalSources">
        <h3>External resources about MD5 in MariaDDB:</h3>
        <a href="https://mariadb.com/kb/en/md5/" target="_blank"><i className="fas fa-external-link-alt" aria-hidden="true"></i> MariaDB.com</a>
        
    </div>
    
</article>
         </main>
        <Footer />
        </div>
    );
    }

export default MariaDB;