import Footer from "../Footer/Footer";

const Javascript = () => {
    return (
        <div>
         <main className="container">
                   <div className="row justify-content-md-center">
                    <h1 className="col text-center"><a href="/"><i className="fa fa-home pr-4"></i>function md5()</a></h1>
                </div>
            <div className="row justify-content-md-center py-4">
    <h2 className="col text-center ">Using MD5 algorithm in Javascript</h2>
</div>
            <article className="row">

    <div className="col-12">There is no native function for MD5 encryption in JavaScript. Fortunately, there are clever programmers who have prepared and made available their own functional solution that we can use. Here is example with the blueimp library.</div>

    
    <div className="col-12 mt-4"><h3>blueimp / JavaScript-MD5</h3>
        <p>JavaScript MD5 implementation by <a href="https://github.com/blueimp/JavaScript-MD5" target="_blank>"><i className="fas fa-external-link-alt" aria-hidden="true"></i> Sebastian Tschan</a>. Compatible with server-side environments like node.js, module loaders like RequireJS and all web browsers.</p>
        <p>Download library md5.js or md5.min.js from Github: <a href="https://github.com/blueimp/JavaScript-MD5" target="_blank>"><i className="fas fa-external-link-alt" aria-hidden="true"></i> blueimp.github.io/javascript-md5</a></p>
        <pre><code className="language-javascript hljs">&lt;script src=<span className="hljs-string">"md5.min.js"</span>&gt;&lt;/script&gt;
<span className="language-xml"><span className="hljs-tag">&lt;<span className="hljs-name">script</span>&gt;</span><span className="language-javascript">
    <span className="hljs-keyword">let</span> str = <span className="hljs-string">'Hello world!'</span>;
    <span className="hljs-keyword">var</span> strHash = <span className="hljs-title function_">md5</span>(str);
    <span className="hljs-variable language_">console</span>.<span className="hljs-title function_">log</span>(<span className="hljs-string">'The MD5 hash is: '</span> + strHash);
</span><span className="hljs-tag">&lt;/<span className="hljs-name">script</span>&gt;</span></span></code></pre>
    </div>

    <div className="col-12"><h4>The above example will output:</h4>
        <pre><code className="language-plaintext hljs">The MD5 hash is: 86fb269d190d2c85f6e0468ceca42a20</code></pre>
    </div>
       
    <div className="col-12 mt-4">
        <h3>Example #2 with salt before string to be encrypted:</h3>
        <p>In some cases, it is worth using a salted MD5 hash for added security. This means that you add any "salt" before or after the string to be encrypted.</p>
        <pre><code className="language-javascript hljs">&lt;script src=<span className="hljs-string">"md5.min.js"</span>&gt;&lt;/script&gt;
<span className="language-xml"><span className="hljs-tag">&lt;<span className="hljs-name">script</span>&gt;</span><span className="language-javascript">
    <span className="hljs-keyword">let</span> salt = <span className="hljs-string">'i#8^*uu'</span>; <span className="hljs-comment">//your arbitrary secret key</span>
    <span className="hljs-keyword">let</span> str = <span className="hljs-string">'Hello world!'</span>;
    <span className="hljs-keyword">var</span> strHash = <span className="hljs-title function_">md5</span>(salt + str);
    <span className="hljs-variable language_">console</span>.<span className="hljs-title function_">log</span>(<span className="hljs-string">'Salted MD5 hash is: '</span> + strHash);
</span><span className="hljs-tag">&lt;/<span className="hljs-name">script</span>&gt;</span></span></code></pre>
    </div>
    
    <div className="col-12"><h4>Example #2 will output:</h4>
        <pre><code className="language-plaintext hljs">Salted MD5 hash is: 5e5eb686808a7c39710dccf1ce03a4e6</code></pre>
    </div>
    
       
</article>
         </main>
        <Footer />
        </div>
    );
    }

export default Javascript;