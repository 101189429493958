import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import './App.css';
import Home from "./Home/Home";
import Javascript from "./Pages/Javascript";
import MariaDB from "./Pages/MariaDB";
import MySql from "./Pages/MySql";
import Php from "./Pages/Php";
import Python from "./Pages/Python";
import Tsql from "./Pages/Tsql";

function App() {
  return (
     <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/php-md5" element={<Php />} />
        <Route path="/javascript-md5" element={<Javascript />} />
        <Route path="/mysql-md5" element={<MySql />} />
        <Route path="/mariadb-md5" element={<MariaDB />} />
        <Route path="/t-sql-md5" element={<Tsql />} />
        <Route path="/python-md5" element={<Python />} />
      </Routes>
    </Router>
  );
}

export default App;
