import Footer from "../Footer/Footer";

const Tsql = () => {
    return (
        <div>
         <main className="container">
                   <div className="row justify-content-md-center">
                    <h1 className="col text-center"><a href="/"><i className="fa fa-home pr-4"></i>function md5()</a></h1>
                </div>
            <div className="row justify-content-md-center py-4" />
            <h2 className="col text-center ">Using MD5 function in T-SQL</h2>
         <article className="row">

    <div className="col-12">In T-SQL, we can use the <code>HASHBYTES</code> function to encrypt to MD5 for data up to 8000 characters.
        For longer data or binary data we can use the system function <code>fn_repl_hash_binary</code>.</div>

    
    <div className="col-12 mt-4"><h3>Syntax</h3>
        <pre><code className="language-sql hljs">HashBytes(<span className="hljs-string">'MD5'</span>, <span className="hljs-variable">@hashThis</span>)</code></pre>
    </div>
    
    <div className="col-12">
        
        <h3>Basic example:</h3>
        <pre><code className="language-sql hljs"><span className="hljs-keyword">SELECT</span> HashBytes(<span className="hljs-string">'MD5'</span>, <span className="hljs-string">'Hello world!'</span>)</code></pre></div>


    <div className="col-12"><h4>The above example will output:</h4>
        <pre><code className="language-plaintext hljs">0x86FB269D190D2C85F6E0468CECA42A20</code></pre>
        <p>As you can see, the result has 34 characters (prefixed with 0x) and is in upper case. In the next example, we will standardize the output.</p>
    </div>
       
    <div className="col-12 mt-4">
        <h3>Example #2 standardized output</h3>
        <pre><code className="language-sql hljs"><span className="hljs-keyword">SELECT</span> <span className="hljs-built_in">LOWER</span>(<span className="hljs-keyword">CONVERT</span>(<span className="hljs-type">VARCHAR</span>(<span className="hljs-number">32</span>),HashBytes(<span className="hljs-string">'MD5'</span>, <span className="hljs-string">'Hello world!'</span>),<span className="hljs-number">2</span>))</code></pre></div>
    
    <div className="col-12"><h4>Example #2 will output:</h4>
        <pre><code className="language-plaintext hljs">86fb269d190d2c85f6e0468ceca42a20</code></pre>
    </div>
    
        <div className="col-12 mt-4">
        <h3>Example #3 with binary data</h3>
        <pre><code className="language-sql hljs"><span className="hljs-keyword">SELECT</span> <span className="hljs-built_in">LOWER</span>(<span className="hljs-keyword">CONVERT</span>(<span className="hljs-type">VARCHAR</span>(<span className="hljs-number">32</span>),master.sys.fn_repl_hash_binary(<span className="hljs-built_in">CAST</span> (<span className="hljs-string">'Hello world!'</span> <span className="hljs-keyword">AS</span> <span className="hljs-type">varbinary</span>)),<span className="hljs-number">2</span>));</code></pre></div>
    
    <div className="col-12"><h4>Example #3 will output:</h4>
        <pre><code className="language-plaintext hljs">86fb269d190d2c85f6e0468ceca42a20</code></pre>
    </div>
    
    <div className="col-12 mt-4">
        <h3>Example #4 with salt before string to be encrypted:</h3>
        <p>In some cases, it is worth using a salted MD5 hash for added security. This means that you add the string "salt" before or after the string to be encrypted.</p>
        <pre><code className="language-sql hljs"><span className="hljs-keyword">DECLARE</span> <span className="hljs-variable">@salt</span> <span className="hljs-type">VARCHAR</span>(<span className="hljs-number">32</span>) <span className="hljs-operator">=</span> N<span className="hljs-string">'i#8^*uu'</span>
<span className="hljs-keyword">DECLARE</span> <span className="hljs-variable">@str2hash</span> <span className="hljs-type">VARCHAR</span>(<span className="hljs-number">100</span>) <span className="hljs-operator">=</span> N<span className="hljs-string">'Hello world!'</span>

<span className="hljs-keyword">SELECT</span> <span className="hljs-built_in">LOWER</span>(<span className="hljs-keyword">CONVERT</span>(<span className="hljs-type">VARCHAR</span>(<span className="hljs-number">32</span>),HashBytes(<span className="hljs-string">'MD5'</span>, CONCAT(<span className="hljs-variable">@salt</span>, <span className="hljs-variable">@str2hash</span>)),<span className="hljs-number">2</span>))</code></pre></div>
    
    <div className="col-12"><h4>Example #4 will output:</h4>
        <pre><code className="language-plaintext hljs">5e5eb686808a7c39710dccf1ce03a4e6</code></pre>
    </div>
    
    <div className="col-12 externalSources">
        <h3>External resource about T-SQL hashing:</h3>
        <a href="https://docs.microsoft.com/en-us/sql/t-sql/functions/hashbytes-transact-sql" target="_blank"><i className="fas fa-external-link-alt" aria-hidden="true"></i> docs.microsoft.com</a>
        
    </div>
    
</article>
         </main>
        <Footer />
        </div>
    );
    }

export default Tsql;