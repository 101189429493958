const Footer = () => {
    return (
        <footer>
        <div className="row content-md-center mt-2 pt-2">
                    <div className="col-sm-12 text-center"><small>Implementations MD5:</small></div>
                </div>
                <div className="links row">
                    <div className="col-sm-12 text-center">
                        <a href="/php-md5" title="string md5  ( string $str  [, bool $raw_output  ] )">md5() in PHP</a> <small>|</small>
                        <a href="/javascript-md5" title="MD5 Implementation in JavaScript">md5 in JavaScript</a> <small>|</small>
                        <a href="/mysql-md5" title="MySQL: function MD5">md5() in MySQL</a> <small>|</small>
                        <a href="/mariadb-md5" title="MariaDB: function MD5">md5() in MariaDB</a> <small>|</small>
                        <a href="/t-sql-md5" title="T-SQL: function MD5">md5 in T-SQL</a> <small>|</small>
                        <a href="/python-md5" title="MD5 hash in Python">md5 in Python</a> 
                    </div>
                </div>
                <div className="links row">
                    <div className="col-sm-12 text-center">
                        <a href="http://en.wikipedia.org/wiki/Md5" target="_blank" title="MD5 (Message-Digest algorithm 5) on Wikipedia.org" rel="noreferrer">MD5 on Wikipedia.org</a>
                    </div>
                </div>
        </footer>
    );
    }

export default Footer;