import Footer from "../Footer/Footer";

const Php = () => {
    return (
        <div>
         <main className="container">
                   <div className="row justify-content-md-center">
                    <h1 className="col text-center"><a href="/"><i className="fa fa-home pr-4"></i>function md5()</a></h1>
                </div>
            <div className="row justify-content-md-center py-4" />
            <h2 className="col text-center ">Using MD5 algorithm in PHP</h2>
            <article className="row">
                <div className="col-12">Using the MD5 algorithm in PHP is very easy because it has a native md5() function.</div>
                <div className="col-12 mt-4"><h3>Syntax ({">"}= PHP 4):</h3>
                    <pre><code className="language-php hljs">md5(string $string, bool $binary = false): string</code></pre>
                </div>
                <div className="col-12">
        
                <h3>Basic example:</h3>
                <pre><code className="language-php hljs">&lt;?php
                    $str = "Hello world!";
                    echo md5($str);
                    ?&gt;</code></pre></div>


                <div className="col-12"><h4>The above example will output:</h4>
                    <pre><code className="language-plaintext hljs">86fb269d190d2c85f6e0468ceca42a20</code></pre>
                </div>
                <div className="col-12 mt-4">
                    <h3>Example #2 with parameter <code>$binary</code> set to <code>TRUE</code>:</h3>
                    <p>The PHP function also has a second optional parameter <code>$binary</code> (default value = <code>FALSE</code>). When this parameter is set to true, the md5 hash string is returned in raw binary format with a length of 16.</p>
                    <pre><code className="language-php hljs">&lt;?php
                    $str = "Hello world!";
                    echo md5($str, true);
                    ?&gt;</code></pre></div>
                    <div className="col-12"><h4>Example #2 will output (unreadable in the browser):</h4>
                    <pre><code className="language-plaintext hljs">��&�,���F��* </code></pre>
                </div>
                        
                <div className="col-12 mt-4">
                        <h3>Example #3 with $salt before string to be encrypted:</h3>
                        <p>In some cases, it is worth using a salted MD5 hash for added security. This means that you add the string "salt" before or after the string to be encrypted.</p>
                        <pre><code className="language-php hljs">&lt;?php
                $salt = "i#8^*uu"; //your arbitrary secret key
                $str = "Hello world!";
                echo md5($salt . $str, true);
                ?&gt;</code></pre></div>
                <div className="col-12"><h4>Example #3 will output:</h4>
                    <pre><code className="language-plaintext hljs">5e5eb686808a7c39710dccf1ce03a4e6</code></pre>
                </div>
                
                <div className="col-12 externalSources">
                    <h3>External resources about MD5 in PHP:</h3>
                    <a href="https://www.php.net/manual/en/function.md5.php" target="_blank" rel="noreferrer"><i className="fas fa-external-link-alt" aria-hidden="true"></i> PHP.net</a>
                    <a href="https://www.w3resource.com/mysql/encryption-and-compression-functions/md5().php" target="_blank" rel="noreferrer"><i className="fa fa-external-link-alt" aria-hidden="true"></i> w3resource</a>
                </div>
            </article>
         </main>
        <Footer />
        </div>
    );
    }

export default Php;